<template>
  <div class="page-wrapper">
    <div class="container-fluid">
      <div class="d-block d-md-flex justify-content-between">
        <ul id="breadcrumb" class="breadcrumb ProximaNovaRegular mx-4">
          <li>
            <router-link to="/solutions/results">
              <span>Model results</span>
            </router-link>
          </li>
          <li>
            <router-link to="/solutions/stats">
              <span>Sale Statistics</span>
            </router-link>
          </li>
          <li class="ProximaNovaBold">Sales - Insights</li>
        </ul>
        <div>
          <div class="" style="height: 40%">
            <div class="w-100 p-4 pr-5">
              <w-white-dropdown
                :options="marketDropdown"
                :placeHolder="'Select'"
                :selectedOption="selectedMarket"
                :labelText="'Market type'"
                class="mr-3"
                @input="selectMarket($event)"
              ></w-white-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-5">
      <w-tab
        :tabName="activeTab"
        :tabs="tabs"
        @activeTab="changeTab($event)"
      >
      </w-tab>
      <div v-if="activeTab === 'Sales Insights'">
        <div class="mt-5" :class="breakDown ? 'card' : ''">
          <b-row class="no-gutters">
            <b-col lg="4" :class="breakDown ? '' : 'card'">
              <div>
                <pie-chart
                  title="Overall"
                  :series="overallSeries"
                  :colors="overAllPieColor"
                  :showBreakdown="true"
                  :breakdown="breakDown"
                  @getBreakdown="getBreakdown"
                />
              </div>
            </b-col>
            <b-col lg="8" v-if="breakDown" class="breakdownable-card">
              <multiple-donut-chart
                :series1="externalData"
                :series2="internalData"
                :title="multipleTitle"
              />
            </b-col>
          </b-row>
        </div>
        <div class="card mt-5 p-5">
          <b-container fluid>
            <Digital
              :data="digitalExpandData"
              title="Media"
              :stripData="mediaData"
              @getDataBreakdown="getDataBreakdown"
            />
          </b-container>
        </div>
        <div v-for="(data, index) in mediaData" :key="index">
          <div class="card mt-5 p-5" v-if="data.label != 'PR' && data.label != 'Cinema'">
            <b-container fluid>
              <Digital
                v-if="data.type === 'range'"
                :title="data.label"
                :data="data.otherChartData"
                :stripData="data.stripData"
                @getDataBreakdown="getDataBreakdown"
                 :dot="data.backgroundColor"
              />
              <Content
                v-if="data.type === 'bar'"
                :title="data.label"
                :barData="data.otherChartData"
                :stripData="data.stripData"
                 :dot="data.backgroundColor"
              />
            </b-container>
          </div>
        </div>

       <div class="card mt-5 p-5">
            <b-container fluid>
              <Content
                title="Content"
                dot="#fb8c00"
                :stripData="contentData"
                :barData="contentExpandData"
              />
            </b-container>
          </div>
      </div>
      <ROISummary v-if="activeTab === 'ROI Summary'" />
      <w-future-planning
        v-if="activeTab === 'Future Planning'"
      ></w-future-planning>
    </div>
  </div>
</template>
<script>
import Tab from "@/components/Solutions/Tab.vue";
import PieChart from "@/components/Chart/PieChart.vue";
import Digital from "@/components/Solutions/Digital.vue";
import Content from "@/components/Solutions/Content.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
import MultipleDonutChart from "@/components/Chart/MultipleDonutChart.vue";
import ROISummary from "@/pages/DEMO/AIMO/ROISummary.vue";
import FuturePlanning from "@/pages/DEMO/AIMO/FuturePlanning.vue";

export default {
  name: "Insights",
  components: {
    ROISummary,
    "w-tab": Tab,
    "w-white-dropdown": WhiteDropdown,
    "w-future-planning": FuturePlanning,
    PieChart,
    Digital,
    Content,
    MultipleDonutChart,
  },
  data() {
    return {
      overAllPieColor: ["#64ffda", "#ff4081"],
      breakDown: true,
      contentBreakDown: false,
      activeTab: "Sales Insights",
      activeFrom: 1,
      selectedMarket: "",
      tabs: [
        {
          category: "Sales Insights",
        },
        {
          category: "Future Planning",
        },
        {
          category: "ROI Summary",
        },
      ],
      marketDropdown: [
        { text: "All India", id: "allindia" },
        { text: "USA", id: "USA" },
        { text: "HSM", id: "hsm", disable: true },
        { text: "KER", id: "ker", disable: true },
        { text: "KA", id: "KA", disable: true },
      ],
      overallSeries: [
        {
          name: "Overall",
          colorByPoint: true,
          data: [
            {
              name: "Internal",
              y: 23,
              sliced: true,
              selected: true,
            },
            {
              name: "External",
              y: 77,
            },
          ],
        },
      ],
      mediaData: [
        {
          label: "Digital",
          value: 35,
          backgroundColor: "#8e24aa",
          type: "range",
          stripData: [
            {
              label: "Google Analytics",
              value: 35,
              backgroundColor: "#6a1b9a",
            },
            {
              label: "Facebook",
              value: 23,
              backgroundColor: "#8e24aa",
            },
            {
              label: "DV 360",
              value: 5,
              backgroundColor: "#ab47bc",
            },
            {
              label: "Linkedin",
              value: 9,
              backgroundColor: "#ba68c8",
            },
            {
              label: "Instagram",
              value: 15,
              backgroundColor: "#ce93d8",
            },
            {
              label: "Twitter",
              value: 15,
              backgroundColor: "#e1bee7",
            },
          ],
          otherChartData: [
            {
              name: "Video Campaigns",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 10, 21),
                  x2: Date.UTC(2014, 11, 2),
                  y: 0,
                  pointWidth: 16,
                  pointPadding: 0,
                  legendColor: "black",
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Engagement Campaigns",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 2),
                  x2: Date.UTC(2014, 11, 10),
                  y: 1,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Lead Ads",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 10),
                  x2: Date.UTC(2014, 11, 23),
                  y: 2,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Click to website",
              pointWidth: 20,
              color: "red",
              data: [
                {
                  x: Date.UTC(2014, 11, 23),
                  x2: Date.UTC(2014, 11, 30),
                  y: 3,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
          ],
        },
        {
          label: "Television",
          value: 23,
          backgroundColor: "#d81b60",
          type: "bar",
          stripData: [
            {
              label: "Cable Tv",
              value: 35,
              backgroundColor: "#d81b60",
            },
            {
              label: "Local Tv",
              value: 44,
              backgroundColor: "#ec407a",
            },
            {
              label: "Broadcast Tv",
              value: 24,
              backgroundColor: "#f06292",
            },
          ],
          otherChartData: {
            height: 400,
            colors: [
              "#ffdd7c",
              "#b5a9ff",
              "#ffa2a2",
              "#fdbfff",
              "#64E572",
              "#FF9655",
              "#FFF263",
              "#6AF9C4",
            ],
            title: {
              text: "",
            },
            xAxis: {
              categories: ['Television'],
              title: {
                text: null,
              },
              labels: {
                enabled: false,
                style: {
                  color: "#8394b4",
                  fontSize: "16px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
            yAxis: [
              {
                lineWidth: 1,
                title: {
                  text: null,
                },
                labels: {
                  enabled: false,
                },
                gridLineWidth: 0,
              },
              {
                min: 0,
                max: 100,
                title: {
                  text: "",
                  align: "high",
                },
                gridLineDashStyle: "shortdash",
                labels: {
                  overflow: "justify",
                  style: {
                    color: "#8394b4",
                  },
                  formatter: function () {
                    var label = this.axis.defaultLabelFormatter.call(this);
                    return label + "%";
                  },
                },
                opposite: true,
                tickInterval: 25,
              },
            ],
            tooltip: {
              borderColor: "#FFFFFF",
              backgroundColor: "#050505",
              color: "#d0dbee",
              borderWidth: 0,
              style: {
                color: "#d0dbee",
              },
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: false,
                },
                pointPadding: 0.3,
                // colorByPoint: true,
              },
              showInLegend: true,
              series: {
                borderRadiusTopLeft: 25,
                borderRadiusTopRight: 25,
              },
            },
            legend: {
              enabled: true,
              layout: "horizontal",
              align: "left",
              verticalAlign: "bottom",
              accessibility: {
                enabled: true,
              },
              symbolPadding: 10,
              symbolHeight: 20,
              itemMarginTop: 24,
              itemStyle: {
                color: "#8394b4",
                fontWeight: "bold",
                fontSize: "16px",
                padding: "24px",
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                name: "GEC",
                data: [10],
                showInLegend: true,
              },
              {
                name: "Comedy",
                data: [23],
              },
              {
                name: "Reality show",
                data: [48],
              },
              {
                name: "News",
                data: [38],
              },
              {
                name: "Sports",
                data: [78],
              },
            ],
          },
        },
        {
          label: "Event sponsership",
          value: 5,
          backgroundColor: "#1e88e5",
          type: "range",
          stripData: [
            {
              label: "Sports",
              value: 75,
              backgroundColor: "#1e88e5",
            },
            {
              label: "Tv Shows",
              value: 25,
              backgroundColor: "#64b5f6",
            },
          ],
          otherChartData: [
            {
              name: "NFL",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 10, 21),
                  x2: Date.UTC(2014, 11, 2),
                  y: 0,
                  pointWidth: 16,
                  pointPadding: 0,
                  legendColor: "black",
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "NBL",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 2),
                  x2: Date.UTC(2014, 11, 10),
                  y: 1,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "MLB",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 10),
                  x2: Date.UTC(2014, 11, 23),
                  y: 2,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
          ],
        },
        {
          label: "Radio",
          value: 9,
          backgroundColor: "#e53935",
          type: "bar",
          stripData: [
            {
              label: "NewYork",
              value: 35,
              backgroundColor: "#a71313",
            },
            {
              label: "Burbank",
              value: 23,
              backgroundColor: "#c62828",
            },
            {
              label: "Atlanta",
              value: 5,
              backgroundColor: "#e53935",
            },
            {
              label: "San Francisco",
              value: 9,
              backgroundColor: "#f44336",
            },
            {
              label: "Philadelphia",
              value: 15,
              backgroundColor: "#ef5350",
            },
            {
              label: "Minneapolis",
              value: 15,
              backgroundColor: "#e57373",
            },
            {
              label: "Chicago",
              value: 15,
              backgroundColor: "#ffcdd2",
            },
            {
              label: "Detroit",
              value: 13,
              backgroundColor: "#ffe2e6",
            },
          ],
             otherChartData: {
            height: 400,
            colors: [
              "#ffdd7c",
              "#b5a9ff",
              "#ffa2a2",
              "#fdbfff",
              "#64E572",
              "#FF9655",
              "#FFF263",
              "#6AF9C4",
            ],
            title: {
              text: "",
            },
            xAxis: {
              categories: ['Television'],
              title: {
                text: null,
              },
              labels: {
                enabled: false,
                style: {
                  color: "#8394b4",
                  fontSize: "16px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
            yAxis: [
              {
                lineWidth: 1,
                title: {
                  text: null,
                },
                labels: {
                  enabled: false,
                },
                gridLineWidth: 0,
              },
              {
                min: 0,
                max: 100,
                title: {
                  text: "",
                  align: "high",
                },
                gridLineDashStyle: "shortdash",
                labels: {
                  overflow: "justify",
                  style: {
                    color: "#8394b4",
                  },
                  formatter: function () {
                    var label = this.axis.defaultLabelFormatter.call(this);
                    return label + "%";
                  },
                },
                opposite: true,
                tickInterval: 25,
              },
            ],
            tooltip: {
              borderColor: "#FFFFFF",
              backgroundColor: "#050505",
              color: "#d0dbee",
              borderWidth: 0,
              style: {
                color: "#d0dbee",
              },
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: false,
                },
                pointPadding: 0.3,
                // colorByPoint: true,
              },
              showInLegend: true,
              series: {
                borderRadiusTopLeft: 25,
                borderRadiusTopRight: 25,
              },
            },
            legend: {
              enabled: true,
              layout: "horizontal",
              align: "left",
              verticalAlign: "bottom",
              accessibility: {
                enabled: true,
              },
              symbolPadding: 10,
              symbolHeight: 20,
              itemMarginTop: 24,
              itemStyle: {
                color: "#8394b4",
                fontWeight: "bold",
                fontSize: "16px",
                padding: "24px",
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                name: "GEC",
                data: [10],
                showInLegend: true,
              },
              {
                name: "Comedy",
                data: [23],
              },
              {
                name: "Reality show",
                data: [48],
              },
              {
                name: "News",
                data: [38],
              },
              {
                name: "Sports",
                data: [78],
              },
            ],
          },
        },
        {
          label: "Newspaper",
          value: 15,
          backgroundColor: "#5e35b1",
          type: "range",
          stripData: [
            {
              label: "Business News Daily",
              value: 35,
              backgroundColor: "#5e35b1",
            },
            {
              label: "Business News Daily",
              value: 23,
              backgroundColor: "#7e57c2",
            },
            {
              label: "Magazines",
              value: 55,
              backgroundColor: "#b39ddb",
            },
          ],
          otherChartData: [
            {
              name: "Video Campaigns",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 10, 21),
                  x2: Date.UTC(2014, 11, 2),
                  y: 0,
                  pointWidth: 16,
                  pointPadding: 0,
                  legendColor: "black",
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Engagement Campaigns",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 2),
                  x2: Date.UTC(2014, 11, 10),
                  y: 1,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Lead Ads",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 10),
                  x2: Date.UTC(2014, 11, 23),
                  y: 2,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Click to website",
              pointWidth: 20,
              color: "red",
              data: [
                {
                  x: Date.UTC(2014, 11, 23),
                  x2: Date.UTC(2014, 11, 30),
                  y: 3,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
          ],
        },
        {
          label: "Billboards",
          value: 15,
          backgroundColor: "#00acc1",
          type: "range",
          stripData: [
            {
              label: "Billboard",
              value: 35,
              backgroundColor: "#00acc1",
            },
            {
              label: "Transit",
              value: 23,
              backgroundColor: "#26c6da",
            },
            {
              label: "Digital OOH",
              value: 25,
              backgroundColor: "#4dd0e1",
            },
            {
              label: "Street Furniture",
              value: 19,
              backgroundColor: "#80deea",
            },
              {
              label: "POS Display",
              value: 19,
              backgroundColor: "#b2ebf2",
            },
          ],
          otherChartData: [
            {
              name: "Video Campaigns",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 10, 21),
                  x2: Date.UTC(2014, 11, 2),
                  y: 0,
                  pointWidth: 16,
                  pointPadding: 0,
                  legendColor: "black",
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Engagement Campaigns",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 2),
                  x2: Date.UTC(2014, 11, 10),
                  y: 1,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Lead Ads",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 10),
                  x2: Date.UTC(2014, 11, 23),
                  y: 2,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Click to website",
              pointWidth: 20,
              color: "red",
              data: [
                {
                  x: Date.UTC(2014, 11, 23),
                  x2: Date.UTC(2014, 11, 30),
                  y: 3,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
          ],
        },
        {
          label: "PR",
          value: 15,
          backgroundColor: "#00897b",
          type: "range",
          stripData: [
            {
              label: "Digital",
              value: 35,
              backgroundColor: "#00897b",
            },
            {
              label: "Television",
              value: 23,
              backgroundColor: "#00897b",
            },
            {
              label: "Event sponsership",
              value: 5,
              backgroundColor: "#00897b",
            },
            {
              label: "Radio",
              value: 9,
              backgroundColor: "#00897b",
            },
            {
              label: "Newspaper",
              value: 15,
              backgroundColor: "#00897b",
            },
            {
              label: "Billboards",
              value: 15,
              backgroundColor: "#00897b",
            },
            {
              label: "PR",
              value: 15,
              backgroundColor: "#00897b",
            },
            {
              label: "Cinema",
              value: 13,
              backgroundColor: "#00897b",
            },
          ],
          otherChartData: [
            {
              name: "Video Campaigns",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 10, 21),
                  x2: Date.UTC(2014, 11, 2),
                  y: 0,
                  pointWidth: 16,
                  pointPadding: 0,
                  legendColor: "black",
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Engagement Campaigns",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 2),
                  x2: Date.UTC(2014, 11, 10),
                  y: 1,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Lead Ads",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 10),
                  x2: Date.UTC(2014, 11, 23),
                  y: 2,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Click to website",
              pointWidth: 20,
              color: "red",
              data: [
                {
                  x: Date.UTC(2014, 11, 23),
                  x2: Date.UTC(2014, 11, 30),
                  y: 3,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
          ],
        },
        {
          label: "Cinema",
          value: 13,
          backgroundColor: "#fb8c00",
          type: "range",
          stripData: [
            {
              label: "Digital",
              value: 35,
              backgroundColor: "#fb8c00",
            },
            {
              label: "Television",
              value: 23,
              backgroundColor: "#fb8c00",
            },
            {
              label: "Event sponsership",
              value: 5,
              backgroundColor: "#fb8c00",
            },
            {
              label: "Radio",
              value: 9,
              backgroundColor: "#fb8c00",
            },
            {
              label: "Newspaper",
              value: 15,
              backgroundColor: "#fb8c00",
            },
            {
              label: "Billboards",
              value: 15,
              backgroundColor: "#fb8c00",
            },
            {
              label: "PR",
              value: 15,
              backgroundColor: "#fb8c00",
            },
            {
              label: "Cinema",
              value: 13,
              backgroundColor: "#fb8c00",
            },
          ],
          otherChartData: [
            {
              name: "Video Campaigns",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 10, 21),
                  x2: Date.UTC(2014, 11, 2),
                  y: 0,
                  pointWidth: 16,
                  pointPadding: 0,
                  legendColor: "black",
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Engagement Campaigns",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 2),
                  x2: Date.UTC(2014, 11, 10),
                  y: 1,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Lead Ads",
              pointWidth: 20,
              data: [
                {
                  x: Date.UTC(2014, 11, 10),
                  x2: Date.UTC(2014, 11, 23),
                  y: 2,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: "Click to website",
              pointWidth: 20,
              color: "red",
              data: [
                {
                  x: Date.UTC(2014, 11, 23),
                  x2: Date.UTC(2014, 11, 30),
                  y: 3,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            },
          ],
        },
      ],
      contentData: [
        {
          label: "Paid Media",
          value: 61,
          backgroundColor: "#fb8c00",
        },
        {
          label: "Owned Content",
          value: 39,
          backgroundColor: "#ffb74d",
        },
      ],
      externalData: [
        {
          name: "Seasonality",
          y: 55,
          z: 92.9,
          size: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 5 ? this.point.name : null;
            },
            color: "#ffffff",
            distance: -30,
          },
          id: "id1",
        },
        {
          name: "Competition",
          y: 23,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id2",
        },
        {
          name: "GDP",
          y: 12,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id3",
        },
        {
          name: "Dollar value",
          y: 10,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              // display only if larger than 1
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id4",
        },
      ],
      internalData: [
        {
          name: "Seasonality",
          y: 55,
          z: 92.9,
          size: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 5 ? this.point.name : null;
            },
            color: "#ffffff",
            distance: -30,
          },
          id: "id1",
        },
        {
          name: "Competition",
          y: 23,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id2",
        },
        {
          name: "GDP",
          y: 12,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id3",
        },
        {
          name: "Dollar value",
          y: 10,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id4",
        },
      ],
      contentExpandData:  {
            height: 400,
            colors: [
              "#ffdd7c",
              "#b5a9ff",
              "#ffa2a2",
              "#fdbfff",
              "#64E572",
              "#FF9655",
              "#FFF263",
              "#6AF9C4",
            ],
            title: {
              text: "",
            },
            xAxis: {
              categories: ['Television'],
              title: {
                text: null,
              },
              labels: {
                enabled: false,
                style: {
                  color: "#8394b4",
                  fontSize: "16px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
            yAxis: [
              {
                lineWidth: 1,
                title: {
                  text: null,
                },
                labels: {
                  enabled: false,
                },
                gridLineWidth: 0,
              },
              {
                min: 0,
                max: 100,
                title: {
                  text: "",
                  align: "high",
                },
                gridLineDashStyle: "shortdash",
                labels: {
                  overflow: "justify",
                  style: {
                    color: "#8394b4",
                  },
                  formatter: function () {
                    var label = this.axis.defaultLabelFormatter.call(this);
                    return label + "%";
                  },
                },
                opposite: true,
                tickInterval: 25,
              },
            ],
            tooltip: {
              borderColor: "#FFFFFF",
              backgroundColor: "#050505",
              color: "#d0dbee",
              borderWidth: 0,
              style: {
                color: "#d0dbee",
              },
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: false,
                },
                pointPadding: 0.3,
                // colorByPoint: true,
              },
              showInLegend: true,
              series: {
                borderRadiusTopLeft: 25,
                borderRadiusTopRight: 25,
              },
            },
            legend: {
              enabled: true,
              layout: "horizontal",
              align: "left",
              verticalAlign: "bottom",
              accessibility: {
                enabled: true,
              },
              symbolPadding: 10,
              symbolHeight: 20,
              itemMarginTop: 24,
              itemStyle: {
                color: "#8394b4",
                fontWeight: "bold",
                fontSize: "16px",
                padding: "24px",
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                name: "GEC",
                data: [10],
                showInLegend: true,
              },
              {
                name: "Comedy",
                data: [23],
              },
              {
                name: "Reality show",
                data: [48],
              },
              {
                name: "News",
                data: [38],
              },
              {
                name: "Sports",
                data: [78],
              },
            ],
          },
      digitalExpandData: [
        {
          name: "Video Campaigns",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 10, 21),
              x2: Date.UTC(2014, 11, 2),
              y: 0,
              pointWidth: 16,
              pointPadding: 0,
              legendColor: "black",
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Engagement Campaigns",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 11, 2),
              x2: Date.UTC(2014, 11, 10),
              y: 1,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Lead Ads",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 11, 10),
              x2: Date.UTC(2014, 11, 23),
              y: 2,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Click to website",
          pointWidth: 20,
          color: "red",
          data: [
            {
              x: Date.UTC(2014, 11, 23),
              x2: Date.UTC(2014, 11, 30),
              y: 3,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
      ],
      multipleTitle: ["External", "Internal"],
    };
  },

  created() {
    this.selectedMarket = JSON.parse(sessionStorage.getItem("kpiMarket"));
    this.breakDown = true
  },
  methods: {
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    getDataBreakdown(data) {
      console.log(data);
    },
    getBreakdown(newVal) {
      this.breakDown = newVal;
      console.log(this.breakDown, "this.breakDownthis.breakDownthis.breakDown");
    },
    getContentBreakdown(newVal) {
      this.contentBreakDown = newVal;
    },

    selectMarket(e) {
      var defaultOption = JSON.parse(sessionStorage.getItem("kpiMarket"));
      if (e === "Select") {
        if (e === this.selectedMarket) {
          return;
        }
        this.selectedMarket = defaultOption;
        return;
      }
      this.selectedMarket = e;
    },
  },
};
</script>

<style scoped>
.page-wrapper {
  margin-top: 70px;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}

.card-heading {
  font-size: 18px;
  font-weight: bold;
}

.breakdownable-card {
  border-left: 2px solid #d4dae4;
  position: relative;
}

.breakdownable-card::before {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  background: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: -17px;
  z-index: 9999;
  clip-path: polygon(50% 0, 0 50%, 50% 100%);
}

.breakdownable-card::after {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  background: #d4dae4;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
  z-index: 999;
  clip-path: polygon(50% 0, 0 50%, 50% 100%);
}

.item {
  margin-top: 20px;
}

.title {
  font-family: ProximaNovaRegular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9aafd4;
  margin-bottom: 5px;
}

::v-deep .progress-bar {
  text-align: end;
  padding-right: 6px;
}
</style>
