<template>
  <div>
    <div class="page-wrapper chiller-theme">
      <main class="page-content">
        <div class="container-fluid">
          <div class="row bottom_header w-100 mx-0 mt-4">
            <div class="w-100 p-0">
              <div class="container-fluid tab-panel p-0">
                <div class="card w-100">
                  <div class="card-body p-0">
                    <div>
                      <w-chartoverview
                        :chartSummary="salseStatics"
                      ></w-chartoverview>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <!-- Marketing RIO -->
            <div class="w-100">
              <div class="container-fluid tab-panel mt-5 px-3 graph-section">
                <div class="card w-100">
                  <div class="card-body row">
                    <div class="col-6 fit-content border_right">
                      <div
                        class="card-header font-large ProximaNovaBold"
                        style="
                          padding: 1.5rem 1.5rem;
                          background: transparent;
                          border: 0px !important;
                        "
                      >
                        Marketing ROI
                      </div>
                      <w-columnchart :chartData="marketingRio" />
                    </div>
                    <div class="col-6 row">
                      <div class="col-6 fit-content">
                        <div
                          class="
                            card-header
                            font-large
                            ProximaNovaBold
                            text-center
                          "
                          style="
                            padding: 1.5rem 1.5rem;
                            background: transparent;
                            border: 0px !important;
                          "
                        >
                          Short term ROI
                        </div>
                        <pie-chart :series="series" />
                      </div>
                      <div class="col-6 fit-content">
                        <div
                          class="
                            card-header
                            font-large
                            ProximaNovaBold
                            text-center
                          "
                          style="
                            padding: 1.5rem 1.5rem;
                            background: transparent;
                            border: 0px !important;
                          "
                        >
                          Long term ROI
                        </div>
                        <pie-chart :series="series" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Digital -->
            <div class="w-100">
              <div class="container-fluid tab-panel mt-5 px-3">
                <div v-for="(data, index) in mediaData" :key="index">
                  <div class="card w-100 px-5 py-4 my-5">
                    <ColumnWithColumn
                      :columnData1="data.columnData1"
                      :columnData2="
                        data.columnData2.find(
                          (el) => data.active === el.title.text
                        )
                      "
                      :options="data.columnData2.map((el) => el.title.text)"
                      :selectedOptionIndex="
                        data.columnData2.findIndex(
                          (el) => data.active === el.title.text
                        )
                      "
                      :index="index"
                      @updateActive="updateActiveMedia"
                    />
                    <!-- <div class="card-body row">
                      <div class="col-12 fit-content">
                        <div
                          class="card-header font-large ProximaNovaBold"
                          style="
                            padding: 1.5rem 1.5rem;
                            background: transparent;
                            border: 0px !important;
                          "
                        >
                          Digital
                        </div>
                        <button
                          class="hide-breakdown btn-default float-right"
                          @click="digitalBreakdown = !digitalBreakdown"
                        >
                          <i
                            class="fas fa-eye"
                            style="align-items: left"
                            v-if="digitalBreakdown"
                          ></i>
                          <img
                            src="@/assets/Icons/hide-password.svg"
                            alt=""
                            v-if="!digitalBreakdown"
                          />
                          Breakdown
                        </button>
                        <w-columnchart :chartData="digital" />
                        <div v-if="digitalBreakdown" class="border_top_divider">
                          <div
                            class="card-header font-large ProximaNovaBold"
                            style="
                              padding: 1.5rem 1.5rem;
                              background: transparent;
                              border: 0px !important;
                            "
                          >
                            {{ digitalTitle.text }}

                            <div
                              class="
                                dropdown-breakdown
                                float-right
                                position-relative
                              "
                            >
                              <w-dropdown
                                :options="digitaldropdown"
                                :placeHolder="'Select'"
                                class="mr-3"
                                dropdownWidth="100%"
                                bgColor="#fff"
                                labelColor="#727988"
                                boxShadow="box-shadow: 0 0 8px 0 #c9ced5;"
                                :selectedOption="digitaldropdown[0]"
                                @input="selectedDigitalDropdown($event)"
                              ></w-dropdown>
                            </div>
                          </div>
                          <w-columnchart :chartData="digitalMedia" />
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import ChartOverview from "@/components/Summary/ChartOverview.vue";
import ColumnChart from "@/components/Chart/ColumnChart.vue";
import PieChart from "@/components/Chart/PieChart.vue";
import ColumnWithColumn from "@/components/ColumnWithColumn.vue";

export default {
  name: "Summary",
  components: {
    // "w-tab": Tab,
    "w-chartoverview": ChartOverview,
    "w-columnchart": ColumnChart,
    PieChart,
    ColumnWithColumn,
  },
  data() {
    return {
      digitalBreakdown: false,
      contentBreakdown: false,
      series: [
        {
          name: "Brands",
          colorByPoint: true,
          data: [
            {
              name: "Content",
              y: 23,
              color: "#ffdd7c",
            },
            {
              name: "Digital",
              y: 77,
              sliced: true,
              selected: true,
              color: "#faacfe",
            },
          ],
        },
      ],
      marketTypedropdown: [
        { text: "USA", id: "1" },
        { text: "INDIA", id: "2" },
        { text: "UK", id: "3" },
      ],
      digitalTitle: "",

      contentTitle: "",
      contentdropdown: [
        { text: "Owned content", id: "1" },
        { text: "Paid Media", id: "2" },
      ],
      activeTab: "ROI summary",
      tabs: [
        {
          category: "Sales Insights",
        },
        {
          category: "Future Planning",
        },
        {
          category: "ROI summary",
        },
      ],
      activeFrom: 1,
      salseStatics: [],
      marketingRio: {
        data: [
          {
            name: "Base Sales",
            data: [0, 360],
          },
          {
            name: "Short Term ROI",
            data: [200, 240],
          },
          {
            name: "Long Term ROI",
            data: [0, 240],
          },
        ],
        color: ["#67e8ff", "#b5a9ff", "#ffdd7c"],
        title: {},
        subtitle: {},
        legend: {},
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: {
            enabled: true,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            groupPadding: 0.4,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      investRio: {
        data: [
          {
            name: "Investment",
            data: [30, 60, 30, 12, 30, 43, 50],
          },
          {
            name: "ROI",
            data: [60, 15, 50, 50, 20, 30, 45],
          },
        ],
        categories: [
          "HSM",
          "AP/TS",
          "Karnataka",
          "Tamil Nadu",
          "Maharashtra",
          "Madhya Pradesh",
          "Gujarat",
        ],
        color: ["#67e8ff", "#ffdd7c"],
        title: {
          text: "Investment vs ROI",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      mediaData: [
        {
          active: "Facebook",
          columnData1: {
            data: [
              {
                name: "Investment",
                data: [30, 10, 60, 30, 10, 30, 23],
              },
              {
                name: "ROI",
                data: [50, 30, 50, 50, 20, 50, 22],
              },
            ],
            color: ["#18ffff", "#ffd740"],
            title: {
              text: "Investment vs ROI",
              align: "left",
              margin: 50,
              style: {
                color: "#222a37",
                fontSize: "20px",
                fontFamily: "ProximaNovaBold",
              },
            },
            subtitle: {},
            legend: {
              layout: "horizontal",
              align: "left",
              verticalAlign: "bottom",
              accessibility: {
                enabled: true,
              },
              symbolPadding: 10,
              symbolHeight: 20,
              itemMarginTop: 16,
              itemStyle: {
                color: "#8394b4",
                fontWeight: "bold",
                fontSize: "16px",
                padding: "24px",
              },
              enabled: true,
            },
            xAxis: {
              categories: [
                "HSM",
                "AP/TS",
                "Karnataka",
                "Tamil Nadu",
                "Maharastra",
                "Madhya Pradesh",
                "Gujrat",
              ],
              crosshair: true,
              gridLineDashStyle: "shortdash",
              gridLineWidth: 1,
              lineWidth: 1,
              labels: {
                style: {
                  color: "#a9b6cd",
                  fontSize: "14px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
            yAxis: {
              gridLineDashStyle: "shortdash",
              gridLineWidth: 1,
              lineWidth: 1,
              title: {
                text: "",
              },
              labels: {
                style: {
                  color: "#a9b6cd",
                  fontSize: "12px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
            plotOptions: {
              column: {
                pointPadding: 0.2,
                borderWidth: 0,
                groupPadding: 0.4,
              },
              series: {
                borderRadiusTopLeft: 25,
                borderRadiusTopRight: 25,
              },
            },
            tooltip: {
              headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
              pointFormat:
                '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
              footerFormat: "</table>",
              shared: true,
              useHTML: true,
              shadow: false,
              style: {
                padding: 0,
              },
            },
          },
          columnData2: [
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Linkedin",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50],
                },
              ],
              color: ["#68eeac", "#ff9100"],
              title: {
                text: "Facebook",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              xAxis: {
                categories: ["Instagram", "Facebook", "Youtube", "Twitter"],
                crosshair: true,
                gridLineDashStyle: "shortdash",
                gridLineWidth: 1,
                lineWidth: 1,
                labels: {
                  style: {
                    color: "#a9b6cd",
                    fontSize: "14px",
                    fontFamily: "ProximaNovaRegular",
                  },
                },
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
                gridLineDashStyle: "shortdash",
                gridLineWidth: 1,
                lineWidth: 1,
                labels: {
                  style: {
                    color: "#a9b6cd",
                    fontSize: "12px",
                    fontFamily: "ProximaNovaRegular",
                  },
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
              legend: {
                layout: "horizontal",
                align: "left",
                verticalAlign: "bottom",
                accessibility: {
                  enabled: true,
                },
                symbolPadding: 10,
                symbolHeight: 20,
                itemMarginTop: 16,
                itemStyle: {
                  color: "#8394b4",
                  fontWeight: "bold",
                  fontSize: "16px",
                  padding: "24px",
                },
                enabled: true,
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Instagram",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Twitter",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
          ],
        },
        {
          active: "Facebook",
          columnData1: {
            data: [
              {
                name: "Investment",
                data: [30, 10, 60, 30, 10, 30],
              },
              {
                name: "ROI",
                data: [50, 30, 50, 50, 20, 50],
              },
            ],
            color: ["#40c4ff", "#536dfe"],
            title: {
              text: "Digital",
              align: "left",
              margin: 50,
              style: {
                color: "#222a37",
                fontSize: "20px",
                fontFamily: "ProximaNovaBold",
              },
            },
            subtitle: {},
            legend: {
              layout: "horizontal",
              align: "left",
              verticalAlign: "bottom",
              accessibility: {
                enabled: true,
              },
              symbolPadding: 10,
              symbolHeight: 20,
              itemMarginTop: 16,
              itemStyle: {
                color: "#8394b4",
                fontWeight: "bold",
                fontSize: "16px",
                padding: "24px",
              },
              enabled: true,
            },
            xAxis: {
              categories: [
                "Google Ads",
                "Facebook",
                "Programmatic",
                "Publishers",
                "Instagram",
                "Twitter",
              ],
              crosshair: true,
              gridLineDashStyle: "shortdash",
              gridLineWidth: 1,
              lineWidth: 1,
              labels: {
                style: {
                  color: "#a9b6cd",
                  fontSize: "14px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
            yAxis: {
              gridLineDashStyle: "shortdash",
              gridLineWidth: 1,
              lineWidth: 1,
              title: {
                text: "",
              },
              labels: {
                style: {
                  color: "#a9b6cd",
                  fontSize: "12px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
            plotOptions: {
              column: {
                pointPadding: 0.2,
                borderWidth: 0,
                groupPadding: 0.4,
              },
              series: {
                borderRadiusTopLeft: 25,
                borderRadiusTopRight: 25,
              },
            },
            tooltip: {
              headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
              pointFormat:
                '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
              footerFormat: "</table>",
              shared: true,
              useHTML: true,
              shadow: false,
              style: {
                padding: 0,
              },
            },
          },
          columnData2: [
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Linkedin",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50],
                },
              ],
              color: ["#68eeac", "#ff9100"],
              title: {
                text: "Facebook",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              xAxis: {
                categories: ["Instagram", "Facebook", "Youtube", "Twitter"],
                crosshair: true,
                gridLineDashStyle: "shortdash",
                gridLineWidth: 1,
                lineWidth: 1,
                labels: {
                  style: {
                    color: "#a9b6cd",
                    fontSize: "14px",
                    fontFamily: "ProximaNovaRegular",
                  },
                },
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
                gridLineDashStyle: "shortdash",
                gridLineWidth: 1,
                lineWidth: 1,
                labels: {
                  style: {
                    color: "#a9b6cd",
                    fontSize: "12px",
                    fontFamily: "ProximaNovaRegular",
                  },
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
              legend: {
                layout: "horizontal",
                align: "left",
                verticalAlign: "bottom",
                accessibility: {
                  enabled: true,
                },
                symbolPadding: 10,
                symbolHeight: 20,
                itemMarginTop: 16,
                itemStyle: {
                  color: "#8394b4",
                  fontWeight: "bold",
                  fontSize: "16px",
                  padding: "24px",
                },
                enabled: true,
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Instagram",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Twitter",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
          ],
        },
        {
          active: "Facebook",
          columnData1: {
            data: [
              {
                name: "Investment",
                data: [30, 10, 60],
              },
              {
                name: "ROI",
                data: [50, 30, 50],
              },
            ],
            color: ["#e53935", "#00acc1"],
            title: {
              text: "Television",
              align: "left",
              margin: 50,
              style: {
                color: "#222a37",
                fontSize: "20px",
                fontFamily: "ProximaNovaBold",
              },
            },
            subtitle: {},
            legend: {
              layout: "horizontal",
              align: "left",
              verticalAlign: "bottom",
              accessibility: {
                enabled: true,
              },
              symbolPadding: 10,
              symbolHeight: 20,
              itemMarginTop: 16,
              itemStyle: {
                color: "#8394b4",
                fontWeight: "bold",
                fontSize: "16px",
                padding: "24px",
              },
              enabled: true,
            },
            xAxis: {
              categories: ["Cable TV", "Local TV", "Broadcast"],
              crosshair: true,
              gridLineDashStyle: "shortdash",
              gridLineWidth: 1,
              lineWidth: 1,
              labels: {
                style: {
                  color: "#a9b6cd",
                  fontSize: "14px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
            yAxis: {
              gridLineDashStyle: "shortdash",
              gridLineWidth: 1,
              lineWidth: 1,
              title: {
                text: "",
              },
              labels: {
                style: {
                  color: "#a9b6cd",
                  fontSize: "12px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
            plotOptions: {
              column: {
                pointPadding: 0.3,
                borderWidth: 0,
                groupPadding: 0.4,
              },
              series: {
                borderRadiusTopLeft: 25,
                borderRadiusTopRight: 25,
              },
            },
            tooltip: {
              headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
              pointFormat:
                '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
              footerFormat: "</table>",
              shared: true,
              useHTML: true,
              shadow: false,
              style: {
                padding: 0,
              },
            },
          },
          columnData2: [
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Linkedin",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50],
                },
              ],
              color: ["#68eeac", "#ff9100"],
              title: {
                text: "Facebook",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              xAxis: {
                categories: ["Instagram", "Facebook", "Youtube", "Twitter"],
                crosshair: true,
                gridLineDashStyle: "shortdash",
                gridLineWidth: 1,
                lineWidth: 1,
                labels: {
                  style: {
                    color: "#a9b6cd",
                    fontSize: "14px",
                    fontFamily: "ProximaNovaRegular",
                  },
                },
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
                gridLineDashStyle: "shortdash",
                gridLineWidth: 1,
                lineWidth: 1,
                labels: {
                  style: {
                    color: "#a9b6cd",
                    fontSize: "12px",
                    fontFamily: "ProximaNovaRegular",
                  },
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
              legend: {
                layout: "horizontal",
                align: "left",
                verticalAlign: "bottom",
                accessibility: {
                  enabled: true,
                },
                symbolPadding: 10,
                symbolHeight: 20,
                itemMarginTop: 16,
                itemStyle: {
                  color: "#8394b4",
                  fontWeight: "bold",
                  fontSize: "16px",
                  padding: "24px",
                },
                enabled: true,
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Instagram",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Twitter",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
          ],
        },
        {
          active: "Facebook",
          columnData1: {
            data: [
              {
                name: "Investment",
                data: [30, 10],
              },
              {
                name: "ROI",
                data: [50, 30],
              },
            ],
            color: ["#ff6e40", "#ffab40"],
            title: {
              text: "Event Sponsorship",
              align: "left",
              margin: 50,
              style: {
                color: "#222a37",
                fontSize: "20px",
                fontFamily: "ProximaNovaBold",
              },
            },
            subtitle: {},
            legend: {
              layout: "horizontal",
              align: "left",
              verticalAlign: "bottom",
              accessibility: {
                enabled: true,
              },
              symbolPadding: 10,
              symbolHeight: 20,
              itemMarginTop: 16,
              itemStyle: {
                color: "#8394b4",
                fontWeight: "bold",
                fontSize: "16px",
                padding: "24px",
              },
              enabled: true,
            },
            xAxis: {
              categories: ["Cable TV", "Tv Shows"],
              crosshair: true,
              gridLineDashStyle: "shortdash",
              gridLineWidth: 1,
              lineWidth: 1,
              labels: {
                style: {
                  color: "#a9b6cd",
                  fontSize: "14px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
            yAxis: {
              gridLineDashStyle: "shortdash",
              gridLineWidth: 1,
              lineWidth: 1,
              title: {
                text: "",
              },
              labels: {
                style: {
                  color: "#a9b6cd",
                  fontSize: "12px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
            plotOptions: {
              column: {
                pointPadding: 0.4,
                borderWidth: 0,
                groupPadding: 0.4,
              },
              series: {
                borderRadiusTopLeft: 25,
                borderRadiusTopRight: 25,
              },
            },
            tooltip: {
              headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
              pointFormat:
                '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
              footerFormat: "</table>",
              shared: true,
              useHTML: true,
              shadow: false,
              style: {
                padding: 0,
              },
            },
          },
          columnData2: [
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Linkedin",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50],
                },
              ],
              color: ["#68eeac", "#ff9100"],
              title: {
                text: "Facebook",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              xAxis: {
                categories: ["Instagram", "Facebook", "Youtube", "Twitter"],
                crosshair: true,
                gridLineDashStyle: "shortdash",
                gridLineWidth: 1,
                lineWidth: 1,
                labels: {
                  style: {
                    color: "#a9b6cd",
                    fontSize: "14px",
                    fontFamily: "ProximaNovaRegular",
                  },
                },
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
                gridLineDashStyle: "shortdash",
                gridLineWidth: 1,
                lineWidth: 1,
                labels: {
                  style: {
                    color: "#a9b6cd",
                    fontSize: "12px",
                    fontFamily: "ProximaNovaRegular",
                  },
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
              legend: {
                layout: "horizontal",
                align: "left",
                verticalAlign: "bottom",
                accessibility: {
                  enabled: true,
                },
                symbolPadding: 10,
                symbolHeight: 20,
                itemMarginTop: 16,
                itemStyle: {
                  color: "#8394b4",
                  fontWeight: "bold",
                  fontSize: "16px",
                  padding: "24px",
                },
                enabled: true,
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Instagram",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Twitter",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
          ],
        },
        {
          active: "Facebook",
          columnData1: {
            data: [
              {
                name: "Investment",
                data: [30, 10, 60, 30, 10, 30, 42, 14],
              },
              {
                name: "ROI",
                data: [50, 30, 50, 50, 20, 50, 34, 63],
              },
            ],
            color: ["#5e35b1", "#fb8c00"],
            title: {
              text: "Radio",
              align: "left",
              margin: 50,
              style: {
                color: "#222a37",
                fontSize: "20px",
                fontFamily: "ProximaNovaBold",
              },
            },
            subtitle: {},
            legend: {
              layout: "horizontal",
              align: "left",
              verticalAlign: "bottom",
              accessibility: {
                enabled: true,
              },
              symbolPadding: 10,
              symbolHeight: 20,
              itemMarginTop: 16,
              itemStyle: {
                color: "#8394b4",
                fontWeight: "bold",
                fontSize: "16px",
                padding: "24px",
              },
              enabled: true,
            },
            xAxis: {
              categories: [
                "NewYork",
                "Burbank",
                "Atlanta",
                "San Francisco",
                "Philadelphia",
                "Minneapolis",
                "Chicago",
                "Detroit",
              ],
              crosshair: true,
              gridLineDashStyle: "shortdash",
              gridLineWidth: 1,
              lineWidth: 1,
              labels: {
                style: {
                  color: "#a9b6cd",
                  fontSize: "14px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
            yAxis: {
              gridLineDashStyle: "shortdash",
              gridLineWidth: 1,
              lineWidth: 1,
              title: {
                text: "",
              },
              labels: {
                style: {
                  color: "#a9b6cd",
                  fontSize: "12px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
            plotOptions: {
              column: {
                pointPadding: 0.2,
                borderWidth: 0,
                groupPadding: 0.4,
              },
              series: {
                borderRadiusTopLeft: 25,
                borderRadiusTopRight: 25,
              },
            },
            tooltip: {
              headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
              pointFormat:
                '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
              footerFormat: "</table>",
              shared: true,
              useHTML: true,
              shadow: false,
              style: {
                padding: 0,
              },
            },
          },
          columnData2: [
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Linkedin",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50],
                },
              ],
              color: ["#68eeac", "#ff9100"],
              title: {
                text: "Facebook",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              xAxis: {
                categories: ["Instagram", "Facebook", "Youtube", "Twitter"],
                crosshair: true,
                gridLineDashStyle: "shortdash",
                gridLineWidth: 1,
                lineWidth: 1,
                labels: {
                  style: {
                    color: "#a9b6cd",
                    fontSize: "14px",
                    fontFamily: "ProximaNovaRegular",
                  },
                },
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
                gridLineDashStyle: "shortdash",
                gridLineWidth: 1,
                lineWidth: 1,
                labels: {
                  style: {
                    color: "#a9b6cd",
                    fontSize: "12px",
                    fontFamily: "ProximaNovaRegular",
                  },
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
              legend: {
                layout: "horizontal",
                align: "left",
                verticalAlign: "bottom",
                accessibility: {
                  enabled: true,
                },
                symbolPadding: 10,
                symbolHeight: 20,
                itemMarginTop: 16,
                itemStyle: {
                  color: "#8394b4",
                  fontWeight: "bold",
                  fontSize: "16px",
                  padding: "24px",
                },
                enabled: true,
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Instagram",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Twitter",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
          ],
        },
        {
          active: "Facebook",
          columnData1: {
            data: [
              {
                name: "Investment",
                data: [30, 10, 60],
              },
              {
                name: "ROI",
                data: [50, 30, 50],
              },
            ],
            color: ["#db1b60", "#1e88e5"],
            title: {
              text: "Print",
              align: "left",
              margin: 50,
              style: {
                color: "#222a37",
                fontSize: "20px",
                fontFamily: "ProximaNovaBold",
              },
            },
            subtitle: {},
            legend: {
              layout: "horizontal",
              align: "left",
              verticalAlign: "bottom",
              accessibility: {
                enabled: true,
              },
              symbolPadding: 10,
              symbolHeight: 20,
              itemMarginTop: 16,
              itemStyle: {
                color: "#8394b4",
                fontWeight: "bold",
                fontSize: "16px",
                padding: "24px",
              },
              enabled: true,
            },
            xAxis: {
              categories: [
                "Business News Daily",
                "General News Daily",
                "Magazines",
              ],
              crosshair: true,
              gridLineDashStyle: "shortdash",
              gridLineWidth: 1,
              lineWidth: 1,
              labels: {
                style: {
                  color: "#a9b6cd",
                  fontSize: "14px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
            yAxis: {
              gridLineDashStyle: "shortdash",
              gridLineWidth: 1,
              lineWidth: 1,
              title: {
                text: "",
              },
              labels: {
                style: {
                  color: "#a9b6cd",
                  fontSize: "12px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
            plotOptions: {
              column: {
                pointPadding: 0.3,
                borderWidth: 0,
                groupPadding: 0.4,
              },
              series: {
                borderRadiusTopLeft: 25,
                borderRadiusTopRight: 25,
              },
            },
            tooltip: {
              headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
              pointFormat:
                '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
              footerFormat: "</table>",
              shared: true,
              useHTML: true,
              shadow: false,
              style: {
                padding: 0,
              },
            },
          },
          columnData2: [
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Linkedin",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50],
                },
              ],
              color: ["#68eeac", "#ff9100"],
              title: {
                text: "Facebook",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              xAxis: {
                categories: ["Instagram", "Facebook", "Youtube", "Twitter"],
                crosshair: true,
                gridLineDashStyle: "shortdash",
                gridLineWidth: 1,
                lineWidth: 1,
                labels: {
                  style: {
                    color: "#a9b6cd",
                    fontSize: "14px",
                    fontFamily: "ProximaNovaRegular",
                  },
                },
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
                gridLineDashStyle: "shortdash",
                gridLineWidth: 1,
                lineWidth: 1,
                labels: {
                  style: {
                    color: "#a9b6cd",
                    fontSize: "12px",
                    fontFamily: "ProximaNovaRegular",
                  },
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
              legend: {
                layout: "horizontal",
                align: "left",
                verticalAlign: "bottom",
                accessibility: {
                  enabled: true,
                },
                symbolPadding: 10,
                symbolHeight: 20,
                itemMarginTop: 16,
                itemStyle: {
                  color: "#8394b4",
                  fontWeight: "bold",
                  fontSize: "16px",
                  padding: "24px",
                },
                enabled: true,
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Instagram",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Twitter",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
          ],
        },
        {
          active: "Facebook",
          columnData1: {
            data: [
              {
                name: "Investment",
                data: [30, 10, 60, 30, 10],
              },
              {
                name: "ROI",
                data: [50, 30, 50, 50, 20],
              },
            ],
            color: ["#ffd740", "#00897b"],
            title: {
              text: "Billboards/Outdoors",
              align: "left",
              margin: 50,
              style: {
                color: "#222a37",
                fontSize: "20px",
                fontFamily: "ProximaNovaBold",
              },
            },
            subtitle: {},
            legend: {
              layout: "horizontal",
              align: "left",
              verticalAlign: "bottom",
              accessibility: {
                enabled: true,
              },
              symbolPadding: 10,
              symbolHeight: 20,
              itemMarginTop: 16,
              itemStyle: {
                color: "#8394b4",
                fontWeight: "bold",
                fontSize: "16px",
                padding: "24px",
              },
              enabled: true,
            },
            xAxis: {
              categories: [
                "Billboards",
                "Transit",
                "Digital OOH",
                "Street Furniture",
                "POS Display",
              ],
              crosshair: true,
              gridLineDashStyle: "shortdash",
              gridLineWidth: 1,
              lineWidth: 1,
              labels: {
                style: {
                  color: "#a9b6cd",
                  fontSize: "14px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
            yAxis: {
              gridLineDashStyle: "shortdash",
              gridLineWidth: 1,
              lineWidth: 1,
              title: {
                text: "",
              },
              labels: {
                style: {
                  color: "#a9b6cd",
                  fontSize: "12px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
            plotOptions: {
              column: {
                pointPadding: 0.2,
                borderWidth: 0,
                groupPadding: 0.4,
              },
              series: {
                borderRadiusTopLeft: 25,
                borderRadiusTopRight: 25,
              },
            },
            tooltip: {
              headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
              pointFormat:
                '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
              footerFormat: "</table>",
              shared: true,
              useHTML: true,
              shadow: false,
              style: {
                padding: 0,
              },
            },
          },
          columnData2: [
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Linkedin",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50],
                },
              ],
              color: ["#68eeac", "#ff9100"],
              title: {
                text: "Facebook",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              xAxis: {
                categories: ["Instagram", "Facebook", "Youtube", "Twitter"],
                crosshair: true,
                gridLineDashStyle: "shortdash",
                gridLineWidth: 1,
                lineWidth: 1,
                labels: {
                  style: {
                    color: "#a9b6cd",
                    fontSize: "14px",
                    fontFamily: "ProximaNovaRegular",
                  },
                },
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
                gridLineDashStyle: "shortdash",
                gridLineWidth: 1,
                lineWidth: 1,
                labels: {
                  style: {
                    color: "#a9b6cd",
                    fontSize: "12px",
                    fontFamily: "ProximaNovaRegular",
                  },
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
              legend: {
                layout: "horizontal",
                align: "left",
                verticalAlign: "bottom",
                accessibility: {
                  enabled: true,
                },
                symbolPadding: 10,
                symbolHeight: 20,
                itemMarginTop: 16,
                itemStyle: {
                  color: "#8394b4",
                  fontWeight: "bold",
                  fontSize: "16px",
                  padding: "24px",
                },
                enabled: true,
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Instagram",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Twitter",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
          ],
        },
        {
          active: "Facebook",
          columnData1: {
            data: [
              {
                name: "Investment",
                data: [30, 43],
              },
              {
                name: "ROI",
                data: [48, 12],
              },
            ],
            color: ["#40c4ff", "#ffab40"],
            title: {
              text: "Content",
              align: "left",
              margin: 50,
              style: {
                color: "#222a37",
                fontSize: "20px",
                fontFamily: "ProximaNovaBold",
              },
            },
            subtitle: {},
            legend: {
              layout: "horizontal",
              align: "left",
              verticalAlign: "bottom",
              accessibility: {
                enabled: true,
              },
              symbolPadding: 10,
              symbolHeight: 20,
              itemMarginTop: 16,
              itemStyle: {
                color: "#8394b4",
                fontWeight: "bold",
                fontSize: "16px",
                padding: "24px",
              },
              enabled: true,
            },
            xAxis: {
              categories: ["Paid Media", "Owned Content"],
              crosshair: true,
              gridLineDashStyle: "shortdash",
              gridLineWidth: 1,
              lineWidth: 1,
              labels: {
                style: {
                  color: "#a9b6cd",
                  fontSize: "14px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
            yAxis: {
              gridLineDashStyle: "shortdash",
              gridLineWidth: 1,
              lineWidth: 1,
              title: {
                text: "",
              },
              labels: {
                style: {
                  color: "#a9b6cd",
                  fontSize: "12px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
            plotOptions: {
              column: {
                pointPadding: 0.4,
                borderWidth: 0,
                groupPadding: 0.4,
              },
              series: {
                borderRadiusTopLeft: 25,
                borderRadiusTopRight: 25,
              },
            },
            tooltip: {
              headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
              pointFormat:
                '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
              footerFormat: "</table>",
              shared: true,
              useHTML: true,
              shadow: false,
              style: {
                padding: 0,
              },
            },
          },
          columnData2: [
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Linkedin",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50],
                },
              ],
              color: ["#68eeac", "#ff9100"],
              title: {
                text: "Facebook",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              xAxis: {
                categories: ["Instagram", "Facebook", "Youtube", "Twitter"],
                crosshair: true,
                gridLineDashStyle: "shortdash",
                gridLineWidth: 1,
                lineWidth: 1,
                labels: {
                  style: {
                    color: "#a9b6cd",
                    fontSize: "14px",
                    fontFamily: "ProximaNovaRegular",
                  },
                },
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
                gridLineDashStyle: "shortdash",
                gridLineWidth: 1,
                lineWidth: 1,
                labels: {
                  style: {
                    color: "#a9b6cd",
                    fontSize: "12px",
                    fontFamily: "ProximaNovaRegular",
                  },
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
              legend: {
                layout: "horizontal",
                align: "left",
                verticalAlign: "bottom",
                accessibility: {
                  enabled: true,
                },
                symbolPadding: 10,
                symbolHeight: 20,
                itemMarginTop: 16,
                itemStyle: {
                  color: "#8394b4",
                  fontWeight: "bold",
                  fontSize: "16px",
                  padding: "24px",
                },
                enabled: true,
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Instagram",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
            {
              data: [
                {
                  name: "Investment",
                  data: [30, 10, 60, 30, 10, 30],
                },
                {
                  name: "ROI",
                  data: [50, 30, 50, 50, 20, 50],
                },
              ],
              color: ["#b5a9ff", "#67e8ff"],
              title: {
                text: "Twitter",
                align: "left",
                margin: 50,
                style: {
                  color: "#222a37",
                  fontSize: "20px",
                  fontFamily: "ProximaNovaBold",
                },
              },
              subtitle: {},
              legend: {},
              xAxis: {
                categories: [
                  "Google Ads",
                  "Facebook",
                  "Programmatic",
                  "Publishers",
                  "Instagram",
                  "Twitter",
                ],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                  groupPadding: 0.4,
                },
                series: {
                  borderRadiusTopLeft: 25,
                  borderRadiusTopRight: 25,
                },
              },
              tooltip: {
                headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                  '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
                shadow: false,
                style: {
                  padding: 0,
                },
              },
            },
          ],
        },
      ],
      digital: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "ROI",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        color: ["#b5a9ff", "#67e8ff"],
        title: {
          text: "Investment Vs ROI",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {},
        legend: {},
        xAxis: {
          categories: [
            "Google Ads",
            "Facebook",
            "Programmatic",
            "Publishers",
            "Instagram",
            "Twitter",
          ],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            groupPadding: 0.4,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      content: {
        data: [
          {
            name: "Investment",
            data: [30, 43],
          },
          {
            name: "ROI",
            data: [48, 12],
          },
        ],
        categories: ["Paid Media", "Owned Content"],
        color: ["#b5a9ff", "#ffdd7c"],
        title: {},
        subtitle: {},
        legend: { enabled: true },
        xAxis: {
          categories: ["Paid Media", "Owned Content"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            groupPadding: 0.46,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      digitalMedia: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30],
          },
          {
            name: "ROI",
            data: [50, 30, 50, 50],
          },
        ],
        color: ["#ffa2a2", "#faacfe"],
        xAxis: {
          categories: ["Instagram", "Facebook", "Youtube", "Twitter"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      classess: [
        "blue-pastel",
        "green-pastel",
        "pink-pastel",
        "red-pastel",
        "yellow-pastel",
        "purple-pastel",
        "green-pastel",
      ],
      activeDigital: "Facebook",
    };
  },
  created() {
    this.salseStatics = [];
    var statics = JSON.parse(sessionStorage.getItem("selectedPlan"));
    this.selectedstat = { text: statics.title, id: statics.title };
    for (var i = 0; i < statics.statics.length; i++) {
      this.salseStatics.push({
        name: statics.statics[i].name,
        value: statics.statics[i].value,
        className: this.classess[i],
      });
    }
  },
  methods: {
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    selectedDigitalDropdown(e) {
      this.digitalTitle = e;
    },
    selectedContentDropdown(e) {
      this.contentTitle = e;
    },
    updateActiveMedia(val) {
      this.mediaData[val.index].active = val.active;
      console.log(this.mediaData, "323232");
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped>
.card_body_padding {
  padding: 30px 24px !important;
}
.fit-content {
  height: fit-content;
}
.hide-breakdown {
  width: 150px;
  -o-object-fit: contain;
  object-fit: contain;
  padding: 12px;
  border: none;
  border-radius: 5px;
  background: #f3f3f3;
  font-size: 15px;
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 45px;
}
.border_right {
  border-right: 1px solid #ccc;
}
.border_right::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: -11px;
  width: 21px;
  height: 20px;
  background: #ffffff;
  border-right: 1px solid #cad5e0;
  border-bottom: 1px solid #cad5e0;
  transform: rotate(134deg);
}
.border_top_divider {
  border-top: 1px solid #ccc;
}
.border_top_divider::after {
  content: "";
  display: block;
  position: absolute;
  top: 47.8%;
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-right: 1px solid #cad5e0;
  border-bottom: 1px solid #cad5e0;
  transform: rotate(224deg);
  left: 25%;
}
.dropdown-breakdown {
  width: 210px;
  object-fit: contain;
  font-size: 15px;
  position: absolute;
  right: 15px;
}
.bottom_header {
  position: sticky;
  top: 80px;
  z-index: 8;
  background: #f2f5fa;
  box-shadow: rgb(233 235 239) 0px 5px 5px 0px;
}
.graph-section {
  margin-top: 125px !important;
}
/* /deep/text.highcharts-title {
  display: none;
} */
</style>
