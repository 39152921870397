<template>
  <b-row style="position: relative">
    <button class="breakdown-btn" @click="togglebreakdown">
      <img
        v-if="!breakDown"
        class="eye-line"
        src="@/assets/eye-close-line.svg"
        alt=""
      />
      <img v-else class="eye-line" src="@/assets/eye-line.png" alt="" /><span
        class="breakdown-text"
        >Breakdown</span
      >
    </button>
    <b-col cols="12" class="px-0">
      <ColumnChart :chartData="columnData1" />
    </b-col>
    <b-col
      cols="12"
      v-if="breakDown"
      class="horizontal-breakdownable-card px-0 pt-5"
      :style="{ '--left': selectedData.value / 2 + valueAdded + '%' }"
    >
      <div class="dropDown-wrapper">
        <w-dropdown
          :options="digitaldropdown"
          :placeHolder="'Select'"
          class="mr-3 dropDown"
          bgColor="#fff"
          labelColor="#727988"
          :selectedOption="digitaldropdown[selectedOptionIndex]"
          @input="selectedDigitalDropdown($event)"
        ></w-dropdown>
      </div>
      <ColumnChart :chartData="columnData2" />
    </b-col>
  </b-row>
</template>
<script>
import ColumnChart from "@/components/Chart/ColumnChart.vue";
import Dropdown from "@/widgets/Dropdown.vue";

export default {
  name: "ColumnWithColumn",
  beforeUpdate() {},
  created() {
    for (let index = 0; index < this.options.length; index++) {
      let obj = {
        text: this.options[index],
        id: index,
        iconHTML: `<i class="fab fa-${this.options[index].toLowerCase()}"></i>`,
      };
      this.digitaldropdown.push(obj);
    }
  },
  components: {
    ColumnChart,
    "w-dropdown": Dropdown,
  },
  props: {
    columnData1: {
      type: Object,
      default: () => {},
    },
    columnData2: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Array,
      default: () => [],
    },
    selectedOptionIndex: {
      type: Number,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      breakDown: false,
      selectedData: "",
      valueAdded: 0,
      digitaldropdown: [],
    };
  },
  methods: {
    findIndexPosition(data) {
      this.valueAdded = 0;
      let ind = this.stripData.findIndex((obj) => obj.label === data.label);
      if (ind > 0) {
        for (var i = 0; i < ind; i++) {
          var value = this.stripData[i].value;
          this.valueAdded += value;
        }
      } else {
        this.valueAdded = 0;
      }
    },
    getDataBreakdown(data) {
      this.selectedData = data.data;
      this.findIndexPosition(data.data);
      this.breakdown(data.breakdown);
      this.$emit("getDataBreakdown", data.data);
    },
    breakdown(newVal) {
      this.breakDown = newVal;
    },
    togglebreakdown() {
      //   this.clickedStripChart(this.chartData[0])
      this.breakDown = !this.breakDown;
    },
    selectedDigitalDropdown(e) {
      this.$emit("updateActive", { active: e.text, index: this.index });
    },
  },
};
</script>

<style scoped>
.horizontal-breakdownable-card {
  border-top: 2px solid #d4dae4;
  position: relative;
}

.horizontal-breakdownable-card::before {
  content: "";
  width: 40px;
  height: 20px;
  position: absolute;
  background: #fff;
  top: -17px;
  transform: translateX(-50%);
  left: 30px;
  z-index: 9999;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.horizontal-breakdownable-card::after {
  content: "";
  width: 40px;
  height: 20px;
  position: absolute;
  background: #d4dae4;
  top: -20px;
  transform: translateX(-50%);
  left: 30px;
  z-index: 999;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
.breakdown-btn {
  width: 136px;
  height: 40px;
  background: #f3f3f3;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: hidden;
  position: absolute;
  right: 0px;
  top: 0px;
}
.breakdown-text {
  font-size: 13px;
  font-weight: 600;
  margin-left: 10px;
}
.eye-line {
  width: 16px;
}
.dropDown-wrapper {
  position: absolute;
  right: 0;
  z-index: 8;
  box-shadow: 0 0 8px 0 #c9ced5;
  width: 210px;
}
</style>
